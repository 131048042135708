@import url(https://fonts.googleapis.com/css2?family=Nerko+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  height: 100vh;
  background: linear-gradient(to right, rgb(110, 231, 183), rgb(192, 132, 252));
  overflow-y: scroll;
  text-align: center;
}

.container {
  height: auto;
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  color: white;
  position: relative;
  background-color: rgb(54, 52, 52);
  border-radius:10px;
  width: 60%;
}

.container-hero {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  position: relative;
  /* background-color: #35aee2; */
}

.authed-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.header {
  font-family: 'Nerko One', cursive;
  margin: 0;
  font-size: 70px;
  font-weight: bold;
}

@media only screen and (max-width: 480px) {
  .header {
    font-size: 35px;
  }

  .sub-text {
    font-size: 20px !important;
  }

  .sub-sub-text {
    font-size:14px !important;
  }

  .container {
    width:80%;
    padding-left:15px;
    padding-right:15px;
  }
}

.sub-text {
  font-weight:bold;
  font-size: 22px;
  align-items:center;
}

.sub-sub-text {
  font-size:18px;
}

.gradient-text {
  background: -webkit-linear-gradient(left, #60c657 30%, #35aee2 60%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cta-button {
  height: 45px;
  border: 0;
  width: 235px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.connect-wallet-button {
  color: white;
  background: -webkit-linear-gradient(left, #ff8867, #ff52ff);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

.mint-button {
  background: -webkit-linear-gradient(left, #4e44ce, #35aee2);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  margin-left: 10px;
  color:white;
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.twitter-logo {
  width: 30px;
  height: 30px;
  vertical-align: text-bottom;
}

.footer-text {
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-decoration: none;
  padding-left:0.5em;
}

.connected-container input[type='text'] {
  display: inline-block;
  padding: 10px;
  width: 50%;
  height: 60px;
  font-size: 16px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 10px;
  margin: 50px auto;
}

.connected-container button {
  height: 50px;
}

a {
  text-decoration: underline;
  color:white;
}

.divider {
  height:40px;
}

/* KeyFrames */
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.machine-container {
  display: flex;
  flex-direction: column;
}

.gif-container {
  display: flex;
  flex-direction: column;
}

.gif-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  grid-gap: 1.5rem;
  justify-items: center;
  margin: 0;
  padding: 20px 0 20px 0;
}

.gif-grid .gif-item {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-self: center;
  align-self: center;
}

.gif-item img {
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
}

.timer-container .timer-header {
  font-size: 20px;
  font-weight: bold;
}

.timer-container .timer-value {
  font-size: 18px;
}

